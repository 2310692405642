import { FlexChild, TwoThirdsChild } from 'components/common';
import { DiscussionEmbed } from 'disqus-react';
import React from 'react';
import { Wrapper } from './style';

const PostComments = ({ postId, postTitle }) => {
  const disqusShortname = 'gfargo-blog';
  const disqusConfig = {
    identifier: postId,
    title: postTitle,
  };

  return (
    <Wrapper>
      <FlexChild as={TwoThirdsChild}>
        <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
      </FlexChild>
    </Wrapper>
  );
};

export default PostComments;
