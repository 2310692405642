import PostComments from 'components/blog/post/Comments';
import PostMeta from 'components/blog/post/Meta';
import {
  Container,
  Content,
  Layout,
  PostContainer,
  Seo,
} from 'components/common';
import { Intro } from 'components/sections';
import { graphql } from 'gatsby';
import useCodeHighlighting from 'hooks/useCodeHighlighting';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

const BlogPost = ({ data, pageContext }) => {
  const { wpPost: post } = data;
  const ref = useRef();

  const {
    content,
    categories,
    tags,
    title,
    date,
    postId,
    featuredImage,
    // author,  // TODO: Create author byline(?)
  } = post;

  useCodeHighlighting({ ref });

  return (
    <Layout context={pageContext}>
      <Seo title={`${title} | Blog Post`} />
      <Intro
        title={title}
        subtitle={date}
        image={
          featuredImage
            ? {
                alt: `${title} featured image`,
                style: { minWidth: '100%', minHeight: '760px' },
                image:
                  featuredImage.node.localFile.childImageSharp.gatsbyImageData,
              }
            : null
        }
        button={false}
      />
      <PostContainer as={Container}>
        <section className="section">
          <div className="container">
            <>
              <Content
                className="content"
                ref={ref}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </>
          </div>
        </section>
      </PostContainer>
      <Container>
        <div style={{ marginTop: `4rem` }}>
          <PostMeta
            categories={categories.nodes}
            tags={tags.nodes}
            date={date}
          />
        </div>
        <div style={{ marginTop: `4rem` }}>
          <PostComments postId={postId} postTitle={title} />
        </div>
      </Container>
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  fragment PostFields on WpPost {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }

  query BlogPostByID($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      slug
      content
      date(formatString: "MMMM DD, YYYY")
      categories {
        nodes {
          name
          slug
        }
      }
      tags {
        nodes {
          name
          slug
        }
      }
      author {
        node {
          name
          slug
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                transformOptions: { grayscale: false, fit: COVER }
                layout: CONSTRAINED
                height: 600
              )
            }
          }
        }
      }
    }
  }
`;
