import {
  FlexChild,
  FlexContainer,
  OneThirdsChild,
  Tag,
  Thumbnail,
  TwoThirdsChild,
} from 'components/common';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Content, Wrapper } from './style';

const PostMeta = ({ categories, tags, date }) => {
  const {
    fileName: { childImageSharp: image },
  } = useStaticQuery(
    graphql`
      {
        fileName: file(relativePath: { eq: "headshot.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 350, height: 350, layout: CONSTRAINED)
          }
        }
      }
    `
  );

  return (
    <Wrapper>
      <FlexContainer wrap="no-wrap">
        <FlexChild style={{ marginRight: '1.2rem' }} as={OneThirdsChild}>
          <div className="column is-one-third">
            <Thumbnail>
              <GatsbyImage
                image={image.gatsbyImageData}
                className="is-rounded"
                alt="headshot photo"
              />
            </Thumbnail>
          </div>
        </FlexChild>
        <FlexChild as={TwoThirdsChild}>
          <FlexContainer direction="column">
            <FlexChild>
              <Content>
                <p className="heading">Published on</p>
                <p className="title">{date}</p>
              </Content>
            </FlexChild>
            {categories && categories.length ? (
              <FlexChild>
                <Content>
                  <h5 className="heading">Topic:</h5>
                  <FlexContainer>
                    {categories.map((category) => (
                      <Tag key={`${category.slug}cat`}>
                        <Link to={`/topics/${category.slug}/`}>
                          {category.name}
                        </Link>
                      </Tag>
                    ))}
                  </FlexContainer>
                </Content>
              </FlexChild>
            ) : null}

            {tags && tags.length ? (
              <FlexChild className="level level-left">
                <Content>
                  <h5 className="heading">Tags:</h5>
                  <FlexContainer>
                    {tags.map((tag) => (
                      <Tag key={`${tag.slug}tag`}>
                        <Link to={`/tags/${tag.slug}/`}>{tag.name}</Link>
                      </Tag>
                    ))}
                  </FlexContainer>
                </Content>
              </FlexChild>
            ) : null}
          </FlexContainer>
        </FlexChild>
      </FlexContainer>
    </Wrapper>
  );
};

export default PostMeta;
