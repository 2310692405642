import config from 'data/config';
import useThemeVar from 'hooks/useThemeVar';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  .heading {
    display: block;
    font-size: 11px;
    letter-spacing: 1px;
    margin-bottom: 5px;
    text-transform: uppercase;
    ${
      '' /* color: ${({ theme }) =>
      theme === 'light' ? config.theme.black : config.theme.white}; */
    }
  }

  .title {
    color: ${() => useThemeVar(config.theme.darkGray, config.theme.hightlight)};
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.125;
  }
`;
