import hljs from 'highlight.js/lib/core';
import { useEffect, useState } from 'react';

// import 'highlight.js/styles/stackoverflow-dark.css';
// import 'highlight.js/styles/github-dark.css';
import 'highlight.js/styles/base16/dracula.css';

import javascript from 'highlight.js/lib/languages/javascript';
import bash from 'highlight.js/lib/languages/bash';
import php from 'highlight.js/lib/languages/php';
import css from 'highlight.js/lib/languages/css';
import markdown from 'highlight.js/lib/languages/markdown';
import xml from 'highlight.js/lib/languages/xml';
// import python from 'highlight.js/lib/languages/python';
// import actionscript from 'highlight.js/lib/languages/actionscript';
// import yaml from 'highlight.js/lib/languages/yaml';
// import typescript from 'highlight.js/lib/languages/typescript';
// import nginx from 'highlight.js/lib/languages/nginx';
// import ruby from 'highlight.js/lib/languages/ruby';
// import arduino from 'highlight.js/lib/languages/arduino';

const ACTIVE_LANGUAGES = [
  'javascript',
  'bash',
  'php',
  'css',
  'markdown',
  'xml',
];

function registerLanguages() {
  hljs.registerLanguage('javascript', javascript);
  hljs.registerLanguage('bash', bash);
  hljs.registerLanguage('php', php);
  hljs.registerLanguage('css', css);
  hljs.registerLanguage('xml', xml);
  hljs.registerLanguage('markdown', markdown);

  // hljs.registerLanguage('python', python );
  // hljs.registerLanguage('actionscript', actionscript );
  // hljs.registerLanguage('yaml', yaml );
  // hljs.registerLanguage('typescript', typescript );
  // hljs.registerLanguage('nginx', nginx );
  // hljs.registerLanguage('ruby', ruby );
  // hljs.registerLanguage('arduino', arduino );
}

const useCodeHighlighting = ({ cssSelector = 'pre code', ref }) => {
  const [loading, setLoading] = useState(true);

  // console.log('useCodeHighlighting', { loading });

  const initSyntaxHighlighting = () => {
    registerLanguages();

    hljs.configure({
      classPrefix: cssSelector,
      languages: ACTIVE_LANGUAGES,
    });

    const domRef = ref ? ref.current : document;

    // console.log('Initializing Highlight', {
    //   ACTIVE_LANGUAGES,
    //   hljs,
    //   domRef,
    //   cssSelector,
    // });

    domRef.querySelectorAll(cssSelector).forEach((el) => {
      // console.info('init highlight element', el);
      hljs.highlightElement(el);
    });

    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      initSyntaxHighlighting();
    }
  });

  return {
    initSyntaxHighlighting,
    loading,
  };
};

export default useCodeHighlighting;
